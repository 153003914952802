import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Preset } from '../../interfaces/presets';
import { HubAgenciesService } from '../../services/hub-agencies.service';
import { ROLES } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { rotateAnimation } from 'angular-animations';

@Component({
  selector: 'app-presets-dropdown',
  templateUrl: './presets-dropdown.component.html',
  styleUrls: ['./presets-dropdown.component.scss'],
  animations: [
    rotateAnimation({ degrees: 360, duration: 1000 }),
  ]
})
export class PresetsDropdownComponent implements OnInit {

  @Input() selectedPresets: Preset[] = [];
  @Input() editAllowed = true;
  @Input() refreshAllowed = true;
  @Output() presetSelected = new EventEmitter<Preset[]>();

  presetListRefreshToggled = false;
  canEditPresets: boolean;


  constructor(public hubAgenciesService: HubAgenciesService,
              private ls: LocalStorageService) { }

  ngOnInit(): void {
    this.canEditPresets = this.ls.role === ROLES.MANAGER || this.ls.role === ROLES.SUBAGENCY_MANAGER;
  }

  resetPresets() {
    this.selectedPresets = [];
    this.presetSelected.emit(this.selectedPresets);
  }

  selectOnePreset(preset: Preset) {
    this.selectedPresets = [preset];
    this.presetSelected.emit(this.selectedPresets);
  }

  selectPreset(preset: Preset) {
    if (this.selectedPresets.includes(preset)) {
      this.selectedPresets.splice(this.selectedPresets.indexOf(preset), 1);
    } else {
      this.selectedPresets.push(preset);
    }
    this.presetSelected.emit(this.selectedPresets);
  }

  refreshPresetList() {
    this.presetListRefreshToggled = !this.presetListRefreshToggled;
    this.hubAgenciesService.refreshPresets();
  }

}
